import React from "react";
import { useSiteConfig } from "../context";

const SEO = () => {
  const { copies } = useSiteConfig();

  return (
    <>
      <title>
        {copies.meta_title || "Calvin Klein - Unleash Your Inner Explorer"}
      </title>
    </>
  );
};

export default SEO;
