import React, { useEffect, useState } from "react";
import SEO from "../components/SEO";
import { SiteProvider } from "../context";

const Layout = (props) => {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);
  if (!pageLoaded) return null;

  if (!props.data) {
    return null;
  }
  return (
    <SiteProvider
      options={props.pageContext.options}
      region={props.pageContext.region}
      copies={props.data.allPrismicCopies?.nodes}
      location={props.location}
    >
      <SEO />
      {props.children}
    </SiteProvider>
  );
};

export default Layout;
